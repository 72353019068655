html {
  scroll-behavior: smooth
}

.bg-gradient {
  background: linear-gradient(90deg, #3DBFBB 0%, #8635BD 100%);
}

.bg-top {
  background-image: url(./assets/bg-top.png);
  background-repeat: no-repeat;
}

.border-gradient {
  border-image: linear-gradient(90deg, #3DBFBB 0%, #8635BD 100%);
  border-image-slice: 1;
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  box-sizing: initial;
}

.btn-gradient {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 2.94%, #667279 100%);
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.circle{
  width: 20px;
  height: 20px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 2.94%, #667279 100%);
  border-radius: 50%;
  position: absolute;
  top: -60px; /* Start above the screen */
  animation: moveDown 6s ease-in-out infinite;
}

.circle:nth-child(1) {
  left: 20%;
  animation-delay: 0s;
}

.circle:nth-child(2) {
  left: 50%;
  animation-delay: 1s;
}

.circle:nth-child(3) {
  left: 80%;
  animation-delay: 2s;
}

@keyframes moveDown {
  0% {
    top: -60px; /* Start above the screen */
  }
  100% {
    top: 100vh; /* Move to the bottom of the screen */
  }
}

.animate-border {
  color: white;
  text-shadow: 0 1px 0 #000;

  --border-angle: 0turn;
  --main-bg: conic-gradient(from var(--border-angle),
      #010101,
      #010101 5%,
      #010101 60%,
      #010101 95%);

  border: solid 1px #FFFFFF29;
  border-radius: 1em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #fff, #fff 99%, transparent);

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.box:hover {
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.animate-rotate {
  animation: shake-rotate 2.5s ease-in-out infinite;
}

@keyframes shake-rotate {
  0%, 100% {
    transform: rotate(0deg); /* Initial state */
  }
  10% {
    transform: rotate(-10deg); /* Shake left */
  }
  20% {
    transform: rotate(10deg); /* Shake right */
  }
  30% {
    transform: rotate(-5deg); /* Shake left */
  }
  40% {
    transform: rotate(5deg); /* Shake right */
  }
  50% {
    transform: rotate(0deg); /* Return to neutral */
  }
  70% {
    transform: rotate(360deg); /* Rotate 360° */
  }
  80% {
    transform: rotate(360deg); /* Hold at 360° */
  }
  100% {
    transform: rotate(360deg); /* Keep 360° until animation resets */
  }
}


/* ---Sidebar css */

.sidebar {
  width: 250px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  background: #050610;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.5s;
  z-index: 50;
}

.sidebar.active {
  left: 0;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 10px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 10px;
  width: 100%;
}